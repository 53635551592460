import { useSSR } from '@engined/client/contexts/SSRContext.js';
import React from 'react';

const LiveReload: React.FC =
  ENV.ENVIRONMENT === 'development'
    ? function LiveReload() {
        const { cspNonce } = useSSR();
        return (
          <script
            suppressHydrationWarning
            nonce={cspNonce}
            dangerouslySetInnerHTML={{
              __html: `(() => {
      const protocol = location.protocol;
      const host = location.hostname;
      const path = protocol + "//${ENV.DEV_SERVER}/events";

      const eventSource = new EventSource(path);
      let reloading = false;
      eventSource.onmessage = function(message) {
        const event = JSON.parse(message.data);
        if (event.type === 'LOG') {
          console.log(event.message);
        }
        if (event.type === 'ERROR') {
          console.error(event.message);
        }
        if (event.type === 'RELOAD') {
          console.log('Reloading window...');
          if (!reloading) {
            window.location.reload();
            reloading = true;
          }
        }
      }
    })();`,
            }}
          />
        );
      }
    : () => null;

export default LiveReload;
