import { ErrorBoundaryComponent } from '@engined/client/routes.js';
import { getLogger } from '@engined/core/services/logger.js';
import React from 'react';

const logger = getLogger('components/ErrorBoundary');

interface OwnProps {
  errorBoundary: ErrorBoundaryComponent;
  children: React.ReactNode;
}

interface State {
  error: Error;
}

type Props = OwnProps;

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    error: null,
  };

  public override componentDidCatch(error, errorInfo): void {
    this.setState({ error });
    logger.error(error, errorInfo);
  }

  public override render(): React.ReactNode {
    if (this.state.error) {
      return <this.props.errorBoundary error={this.state.error} />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
