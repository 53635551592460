import { useSSR } from '@engined/client/contexts/SSRContext.js';
import { safeStringify } from '@engined/server/helpers/safe.js';
import React from 'react';
import { useMatches } from 'react-router-dom';

let isHydrated = false;

type ScriptProps = Omit<
  React.HTMLProps<HTMLScriptElement>,
  'children' | 'async' | 'defer' | 'src' | 'type' | 'noModule' | 'dangerouslySetInnerHTML' | 'suppressHydrationWarning'
>;

export default function Scripts(props: ScriptProps) {
  const ssr = useSSR();
  const manifest = ssr.manifest;
  const matches = useMatches();

  React.useEffect(() => {
    isHydrated = true;
  }, []);

  const initialScripts = React.useMemo(() => {
    const contextScript = `window.__context = ${safeStringify(ssr)};`;

    return (
      <>
        <script
          {...props}
          id="context-script"
          dangerouslySetInnerHTML={{ __html: contextScript }}
          nonce={ssr.cspNonce}
          suppressHydrationWarning
        />
        <script {...props} src={manifest.entry.module} type="module" nonce={ssr.cspNonce} suppressHydrationWarning />
      </>
    );
    // disabled deps array because we are purposefully only rendering this once
    // for hydration, after that we want to just continue rendering the initial
    // scripts as they were when the page first loaded
    // eslint-disable-next-line
  }, []);

  const routePreloads = matches
    .map((match) => {
      const route = manifest.routes[match.id];
      if (!route) {
        return [];
      }

      return (route.imports || []).concat([route.module]);
    })
    .flat(1);

  const preloads = manifest.entry.imports.concat(routePreloads);

  return (
    <>
      {dedupe(preloads).map((path) => (
        <link key={path} rel="modulepreload" href={path} crossOrigin={props.crossOrigin} nonce={ssr.cspNonce} />
      ))}
      {isHydrated ? null : initialScripts}
    </>
  );
}

function dedupe(array: any[]) {
  return [...new Set(array)];
}
