import Document from '@engined/client/components/Document.js';
import ServerError from '@engined/client/components/ServerError.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { getLogger } from '@engined/core/services/logger.js';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { DefaultTheme } from '@mui/system/index.js';
import React from 'react';
import { useRouteError } from 'react-router-dom';

const logger = getLogger('@engined/client/components/PageServerError');

interface OwnProps<Theme = DefaultTheme> {
  theme: Partial<Theme> | ((outerTheme: Theme) => Theme);
}

type Props<Theme = DefaultTheme> = OwnProps<Theme>;

export default function PageServerError<Theme = DefaultTheme>({ theme }: Props<Theme>) {
  const { language } = useLocale();
  const error = useRouteError();

  logger.error(error);

  return (
    <Document language={language}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ServerError />
      </ThemeProvider>
    </Document>
  );
}
