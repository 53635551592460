import LiveReload from '@engined/client/components/LiveReload.js';
import Meta from '@engined/client/components/Meta.js';
import Scripts from '@engined/client/components/Scripts.js';
import { Language } from '@engined/client/contexts/LocaleContext.js';
import { useSSR } from '@engined/client/contexts/SSRContext.js';
import { dom } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { ScrollRestoration } from 'react-router-dom';

export default function Document({
  children,
  language,
  head,
  scripts,
}: {
  children: React.ReactNode;
  language: Language;
  head?: React.ReactNode;
  scripts?: React.ReactNode;
}) {
  const { csrfToken, cspNonce } = useSSR();

  return (
    <html lang={language} suppressHydrationWarning={true}>
      <head suppressHydrationWarning={true}>
        <meta charSet="utf-8" data-first="true" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap"
          rel="stylesheet"
        />

        <style id="font-awesome-css" nonce={cspNonce} dangerouslySetInnerHTML={{ __html: dom.css() }} />
        {csrfToken && <meta name="csrf-token" content={csrfToken} />}

        <Meta />
        {/*<Links />*/}
        {head}
      </head>
      <body>
        {children}
        {/*<RouteChangeAnnouncement />*/}
        <ScrollRestoration />
        <Scripts />
        {scripts}
        {ENV.ENVIRONMENT === 'development' && <LiveReload />}
      </body>
    </html>
  );
}
