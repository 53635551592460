import { ApolloClient, MutationTuple, useMutation, useSubscription } from '@apollo/client/index.js';
import MuiPickersUtilsProvider from '@asaprint/asap/components/MuiPickersUtilsProvider.js';
import AuthContext, { AuthContextValue } from '@asaprint/asap/contexts/AuthContext.js';
import { App_Load, App_Logout, App_ServerToastReceived } from '@asaprint/asap/root.graphql';
import NotFound from '@asaprint/asap/routes/$.js';
import {
  App_LoadQuery,
  App_LoadQueryVariables,
  App_LogoutMutation,
  App_LogoutMutationVariables,
  App_ServerToastReceivedSubscription,
  App_ServerToastReceivedSubscriptionVariables,
  Me,
  ServerToastType,
} from '@asaprint/asap/schema.client.types.js';
import theme from '@asaprint/asap/theme.js';
import Document from '@engined/client/components/Document.js';
import ErrorBoundaryCatch from '@engined/client/components/ErrorBoundary.js';
import PageServerError from '@engined/client/components/PageServerError.js';
import ServerError from '@engined/client/components/ServerError.js';
import LocaleContext, { createT, Language, LocaleContextValue } from '@engined/client/contexts/LocaleContext.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import { ErrorBoundaryComponent } from '@engined/client/routes.js';
import { LoaderFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { CssBaseline, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/core';
import { SnackbarOrigin, SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { isRouteErrorResponse, Outlet, useRouteError } from 'react-router-dom';

const logger = getLogger('@intranet/root');

const snackbarOrigin: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

function createAuthContext(
  client: ApolloClient<unknown>,
  requestUser: AuthContextValue['requestUser'],
  logoutExecute: MutationTuple<App_LogoutMutation, App_LogoutMutationVariables>[0],
  setAuthContext: React.Dispatch<React.SetStateAction<AuthContextValue>>,
): AuthContextValue {
  return {
    requestUser,
    login(user: Me) {
      setAuthContext((state) => ({
        ...state,
        requestUser: user,
      }));
      Sentry.getCurrentScope().setUser(user);
    },
    async logout() {
      try {
        const response = await logoutExecute();
        window.location.reload();
        // Try to stop all pending queries
        client.stop();
        await client.resetStore();
        setAuthContext((state) => ({
          ...state,
          requestUser: null,
        }));
        Sentry.getCurrentScope().setUser(null);
      } catch (err) {
        logger.error(err);
      }
    },
    setSetting(key: string, value: any) {
      setAuthContext((state) =>
        state.requestUser?.settings?.[key] === value
          ? state
          : {
              ...state,
              requestUser: {
                ...state.requestUser,
                settings: {
                  ...(state.requestUser.settings || {}),
                  [key]: value,
                },
              },
            },
      );
    },
  };
}

const App: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { data: appLoadData, client } = useQuery<App_LoadQuery, App_LoadQueryVariables>(App_Load, {
    nextFetchPolicy: 'cache-first',
  });
  const requestUser = appLoadData?.me;
  const [logoutExecute] = useMutation<App_LogoutMutation, App_LogoutMutationVariables>(App_Logout);
  const [authContext, setAuthContext] = useState<AuthContextValue>(
    createAuthContext(client, requestUser, logoutExecute, (...args) => setAuthContext(...args)),
  );

  useEffect(() => {
    setAuthContext(createAuthContext(client, requestUser, logoutExecute, setAuthContext));
  }, [client, requestUser, logoutExecute, setAuthContext]);

  const [localeContext, setLocaleContext] = useState<LocaleContextValue>({
    language: (appLoadData?.requestLanguage ?? 'sk') as Language,
    t: createT(appLoadData?.requestLanguage ?? 'sk'),
    changeLanguage(lang) {
      setLocaleContext((state) => ({
        ...state,
        language: lang,
        t: createT(lang),
      }));
    },
  });

  // Setup sentry user
  useEffect(() => {
    if (requestUser) {
      Sentry.configureScope((scope) => {
        scope.setUser(requestUser);
      });
    }
  }, [requestUser]);

  return (
    <Document language={localeContext.language}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3} anchorOrigin={snackbarOrigin}>
          <LocaleContext.Provider value={localeContext}>
            <MuiPickersUtilsProvider>
              <AuthContext.Provider value={authContext}>
                <ServerToastReceiver />
                <ErrorBoundaryCatch errorBoundary={ServerError}>{children || <Outlet />}</ErrorBoundaryCatch>
              </AuthContext.Provider>
            </MuiPickersUtilsProvider>
          </LocaleContext.Provider>
        </SnackbarProvider>
      </ThemeProvider>
    </Document>
  );
};

export default App;

export const loader = async ({ context: { req, apollo } }: LoaderFunctionArgs) => {
  // Load App_Load on server
  const result = await apollo.query<App_LoadQuery, App_LoadQueryVariables>({
    query: App_Load,
  });

  return result.data;
};

export const ErrorBoundary: ErrorBoundaryComponent = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <App>
        <NotFound />
      </App>
    );
  } else {
    return <PageServerError theme={theme} />;
  }
};

const ServerToastReceiver: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  useSubscription<App_ServerToastReceivedSubscription, App_ServerToastReceivedSubscriptionVariables>(
    App_ServerToastReceived,
    {
      onData({ data }) {
        const config = data.data.serverToastReceived;
        enqueueSnackbar(config.message, {
          variant: config.type === ServerToastType.Error ? 'error' : 'success',
        });
      },
    },
  );

  return null;
};
