import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { useSSR } from '@engined/client/contexts/SSRContext.js';
import { HtmlMetaDescriptor } from '@engined/client/routes.js';
import { MetaFunction, Schema, SchemaFunction } from '@engined/core/interfaces.js';
import { safeStringify } from '@engined/server/helpers/safe.js';
import React from 'react';
import { useLocation, useMatches } from 'react-router-dom';

export default function Meta() {
  const matches = useMatches();
  const { sentry } = useSSR();
  const location = useLocation();
  const locale = useLocale();

  const meta: HtmlMetaDescriptor = {};
  let schemas: Schema[] = [];
  if (sentry?.trace) {
    meta['sentry-trace'] = sentry.trace;
  }
  if (sentry?.baggage) {
    meta.baggage = sentry.trace;
  }

  for (const match of matches) {
    const handle = match.handle as {
      meta?: MetaFunction | HtmlMetaDescriptor;
      schema?: SchemaFunction | Schema[];
    };
    if (handle?.meta) {
      const routeMeta =
        typeof handle.meta === 'function' ? handle.meta({ match, location, locale, meta, matches }) : handle.meta;
      Object.assign(meta, routeMeta);
    }
    if (handle?.schema) {
      const routeMeta =
        typeof handle.schema === 'function'
          ? handle.schema({ match, location, locale, schemas, matches })
          : handle.schema;
      schemas = schemas.concat(routeMeta);
    }
  }

  return (
    <>
      {Object.entries(meta).map(([name, value]) => {
        if (!value) {
          return null;
        }

        if (['charset', 'charSet'].includes(name)) {
          return <meta key="charset" charSet={value as string} />;
        }

        if (name === 'title') {
          return <title key="title">{value as string}</title>;
        }

        // Open Graph tags use the `property` attribute, while other meta tags
        // use `name`. See https://ogp.me/
        const isOpenGraphTag = name.startsWith('og:');
        return [value].flat().map((content) => {
          if (content === undefined) {
            return null;
          }

          if (isOpenGraphTag) {
            return <meta content={content as string} key={name + content} property={name} />;
          }

          if (typeof content === 'string') {
            return <meta content={content} name={name} key={name + content} />;
          }

          return <meta key={name + JSON.stringify(content)} {...content} />;
        });
      })}
      {schemas.map((schema, i) => (
        <script key={i} type="application/ld+json" dangerouslySetInnerHTML={{ __html: safeStringify(schema) }} />
      ))}
    </>
  );
}
