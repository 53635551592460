import Interpolate from '@engined/client/components/Interpolate.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import {
  SERVER_ERROR_APOLOGIES,
  SERVER_ERROR_MESSAGE,
  SERVER_ERROR_MESSAGE_WITH_REPORT,
  SERVER_ERROR_REPORT,
  SERVER_ERROR_SENTRY_ERROR_FORM_ENTRY,
  SERVER_ERROR_SENTRY_ERROR_GENERIC,
  SERVER_ERROR_SENTRY_LABEL_CLOSE,
  SERVER_ERROR_SENTRY_LABEL_COMMENTS,
  SERVER_ERROR_SENTRY_LABEL_EMAIL,
  SERVER_ERROR_SENTRY_LABEL_NAME,
  SERVER_ERROR_SENTRY_LABEL_SUBMIT,
  SERVER_ERROR_SENTRY_SUBTITLE,
  SERVER_ERROR_SENTRY_SUBTITLE2,
  SERVER_ERROR_SENTRY_SUCCESS_MESSAGE,
  SERVER_ERROR_SENTRY_TITLE,
  SERVER_ERROR_TITLE,
} from '@engined/client/locales.js';
import { Box, Button, Stack, Typography } from '@mui/material';
import Copyright from '@engined/client/components/Copyright.js';
import React, { useEffect, useState } from 'react';

interface OwnProps {}

type Props = OwnProps;

const ServerError: React.FunctionComponent<Props> = () => {
  const { t, language } = useLocale();

  const [sentry, setSentry] = useState(null);

  useEffect(() => {
    import('@sentry/browser').then((module) => setSentry(module));
  }, [setSentry]);

  const onReport = useEventCallback(() => {
    if (sentry.lastEventId()) {
      sentry.showReportDialog({
        lang: language,
        title: t(SERVER_ERROR_SENTRY_TITLE),
        subtitle: t(SERVER_ERROR_SENTRY_SUBTITLE),
        subtitle2: t(SERVER_ERROR_SENTRY_SUBTITLE2),
        labelName: t(SERVER_ERROR_SENTRY_LABEL_NAME),
        labelEmail: t(SERVER_ERROR_SENTRY_LABEL_EMAIL),
        labelComments: t(SERVER_ERROR_SENTRY_LABEL_COMMENTS),
        labelClose: t(SERVER_ERROR_SENTRY_LABEL_CLOSE),
        labelSubmit: t(SERVER_ERROR_SENTRY_LABEL_SUBMIT),
        errorGeneric: t(SERVER_ERROR_SENTRY_ERROR_GENERIC),
        errorFormEntry: t(SERVER_ERROR_SENTRY_ERROR_FORM_ENTRY),
        successMessage: t(SERVER_ERROR_SENTRY_SUCCESS_MESSAGE),
      });
    }
  });

  const lastEventId = sentry?.lastEventId();

  return (
    <>
      <Stack sx={{ mt: 8 }} alignItems="center">
        <Typography variant="h1" component="h1">
          500!
        </Typography>
        <Typography variant="h3" component="h3">
          <Interpolate resource={SERVER_ERROR_TITLE} />
        </Typography>
        <Typography variant="body1" component="p" textAlign="center" sx={{ mt: 2 }}>
          <Interpolate resource={lastEventId ? SERVER_ERROR_MESSAGE_WITH_REPORT : SERVER_ERROR_MESSAGE} />

          <br />

          {t(SERVER_ERROR_APOLOGIES)}

          {lastEventId && (
            <Button color="primary" onClick={onReport}>
              {t(SERVER_ERROR_REPORT)}
            </Button>
          )}
        </Typography>
      </Stack>

      <Box marginTop={8}>
        <Copyright />
      </Box>
    </>
  );
};

export default ServerError;
