import {
  DATE_PICKERS_CANCEL_BUTTON_LABEL,
  DATE_PICKERS_CLEAR_BUTTON_LABEL,
  DATE_PICKERS_NEXT_MONTH,
  DATE_PICKERS_OK_BUTTON_LABEL,
  DATE_PICKERS_PREVIOUS_MONTH,
  DATE_PICKERS_TODAY_BUTTON_LABEL,
} from '@asaprint/asap/locales/client.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { dateFnsLocales } from '@engined/core/helpers/date.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import React, { useMemo } from 'react';

// TODO: '@mui/x-date-pickers/AdapterDateFns' copied here because ESM not working with this package

import {
  addDays,
  addSeconds,
  addMinutes,
  addHours,
  addWeeks,
  addMonths,
  addYears,
  differenceInYears,
  differenceInQuarters,
  differenceInMonths,
  differenceInWeeks,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInMilliseconds,
  eachDayOfInterval,
  endOfDay,
  endOfWeek,
  endOfYear,
  format as dateFnsFormat,
  getDate,
  getDaysInMonth,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  getWeek,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isSameYear,
  isSameMonth,
  isSameHour,
  isValid,
  parse as dateFnsParse,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
  startOfDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  startOfYear,
  parseISO,
  formatISO,
  isWithinInterval,
  getMilliseconds,
  setMilliseconds,
} from 'date-fns';

import defaultLocale from 'date-fns/locale/en-US/index.js';
import longFormatters from 'date-fns/_lib/format/longFormatters/index.js';
import {
  AdapterFormats,
  AdapterOptions,
  AdapterUnits,
  DateBuilderReturnType,
  FieldFormatTokenMap,
  MuiPickersAdapter,
} from '@mui/x-date-pickers';

type DateFnsLocale = typeof defaultLocale;

const formatTokenMap: FieldFormatTokenMap = {
  // Year
  y: { sectionType: 'year', contentType: 'digit', maxLength: 4 },
  yy: 'year',
  yyy: { sectionType: 'year', contentType: 'digit', maxLength: 4 },
  yyyy: 'year',

  // Month
  M: { sectionType: 'month', contentType: 'digit', maxLength: 2 },
  MM: 'month',
  MMMM: { sectionType: 'month', contentType: 'letter' },
  MMM: { sectionType: 'month', contentType: 'letter' },
  L: { sectionType: 'month', contentType: 'digit', maxLength: 2 },
  LL: 'month',
  LLL: { sectionType: 'month', contentType: 'letter' },
  LLLL: { sectionType: 'month', contentType: 'letter' },

  // Day of the month
  d: { sectionType: 'day', contentType: 'digit', maxLength: 2 },
  dd: 'day',
  do: { sectionType: 'day', contentType: 'digit-with-letter' },

  // Day of the week
  E: { sectionType: 'weekDay', contentType: 'letter' },
  EE: { sectionType: 'weekDay', contentType: 'letter' },
  EEE: { sectionType: 'weekDay', contentType: 'letter' },
  EEEE: { sectionType: 'weekDay', contentType: 'letter' },
  EEEEE: { sectionType: 'weekDay', contentType: 'letter' },
  i: { sectionType: 'weekDay', contentType: 'digit', maxLength: 1 },
  ii: 'weekDay',
  iii: { sectionType: 'weekDay', contentType: 'letter' },
  iiii: { sectionType: 'weekDay', contentType: 'letter' },
  e: { sectionType: 'weekDay', contentType: 'digit', maxLength: 1 },
  ee: 'weekDay',
  eee: { sectionType: 'weekDay', contentType: 'letter' },
  eeee: { sectionType: 'weekDay', contentType: 'letter' },
  eeeee: { sectionType: 'weekDay', contentType: 'letter' },
  eeeeee: { sectionType: 'weekDay', contentType: 'letter' },
  c: { sectionType: 'weekDay', contentType: 'digit', maxLength: 1 },
  cc: 'weekDay',
  ccc: { sectionType: 'weekDay', contentType: 'letter' },
  cccc: { sectionType: 'weekDay', contentType: 'letter' },
  ccccc: { sectionType: 'weekDay', contentType: 'letter' },
  cccccc: { sectionType: 'weekDay', contentType: 'letter' },

  // Meridiem
  a: 'meridiem',
  aa: 'meridiem',
  aaa: 'meridiem',

  // Hours
  H: { sectionType: 'hours', contentType: 'digit', maxLength: 2 },
  HH: 'hours',
  h: { sectionType: 'hours', contentType: 'digit', maxLength: 2 },
  hh: 'hours',

  // Minutes
  m: { sectionType: 'minutes', contentType: 'digit', maxLength: 2 },
  mm: 'minutes',

  // Seconds
  s: { sectionType: 'seconds', contentType: 'digit', maxLength: 2 },
  ss: 'seconds',
};

const defaultFormats: AdapterFormats = {
  year: 'yyyy',
  month: 'LLLL',
  monthShort: 'MMM',
  dayOfMonth: 'd',
  weekday: 'EEEE',
  weekdayShort: 'EEE',
  hours24h: 'HH',
  hours12h: 'hh',
  meridiem: 'aa',
  minutes: 'mm',
  seconds: 'ss',

  fullDate: 'PP',
  fullDateWithWeekday: 'PPPP',
  keyboardDate: 'P',
  shortDate: 'MMM d',
  normalDate: 'd MMMM',
  normalDateWithWeekday: 'EEE, MMM d',
  monthAndYear: 'LLLL yyyy',
  monthAndDate: 'MMMM d',

  fullTime: 'p',
  fullTime12h: 'hh:mm aa',
  fullTime24h: 'HH:mm',

  fullDateTime: 'PP p',
  fullDateTime12h: 'PP hh:mm aa',
  fullDateTime24h: 'PP HH:mm',
  keyboardDateTime: 'P p',
  keyboardDateTime12h: 'P hh:mm aa',
  keyboardDateTime24h: 'P HH:mm',
};

export class AdapterDateFns implements MuiPickersAdapter<Date, DateFnsLocale> {
  public isMUIAdapter = true;

  public isTimezoneCompatible = false;

  public lib = 'date-fns';

  public locale?: DateFnsLocale;

  public formats: AdapterFormats;

  public formatTokenMap = formatTokenMap;

  public escapedCharacters = { start: "'", end: "'" };

  constructor({ locale, formats }: AdapterOptions<DateFnsLocale, never> = {}) {
    this.locale = locale;
    this.formats = { ...defaultFormats, ...formats };
  }

  public date = (value?: any) => {
    if (typeof value === 'undefined') {
      return new Date();
    }

    if (value === null) {
      return null;
    }

    return new Date(value);
  };

  public dateWithTimezone = <T extends string | null | undefined>(value: T): DateBuilderReturnType<T, Date> => {
    return this.date(value) as DateBuilderReturnType<T, Date>;
  };

  public getTimezone = (): string => {
    return 'default';
  };

  public setTimezone = (value: Date): Date => {
    return value;
  };

  public toJsDate = (value: Date) => {
    return value;
  };

  public parseISO = (isoString: string) => {
    return parseISO(isoString);
  };

  public toISO = (value: Date) => {
    return formatISO(value, { format: 'extended' });
  };

  public parse = (value: string, format: string) => {
    if (value === '') {
      return null;
    }

    return dateFnsParse(value, format, new Date(), { locale: this.locale });
  };

  public getCurrentLocaleCode = () => {
    return this.locale?.code || 'en-US';
  };

  // Note: date-fns input types are more lenient than this adapter, so we need to expose our more
  // strict signature and delegate to the more lenient signature. Otherwise, we have downstream type errors upon usage.
  public is12HourCycleInCurrentLocale = () => {
    if (this.locale) {
      return /a/.test(this.locale.formatLong!.time());
    }

    // By default, date-fns is using en-US locale with am/pm enabled
    return true;
  };

  public expandFormat = (format: string) => {
    const longFormatRegexp = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;

    // @see https://github.com/date-fns/date-fns/blob/master/src/format/index.js#L31
    return format
      .match(longFormatRegexp)!
      .map((token: string) => {
        const firstCharacter = token[0];
        if (firstCharacter === 'p' || firstCharacter === 'P') {
          const longFormatter = longFormatters[firstCharacter];
          const locale = this.locale || defaultLocale;
          return longFormatter(token, locale.formatLong, {});
        }
        return token;
      })
      .join('');
  };

  public getFormatHelperText = (format: string) => {
    return this.expandFormat(format)
      .replace(/(aaa|aa|a)/g, '(a|p)m')
      .toLocaleLowerCase();
  };

  public isNull = (value: Date | null) => {
    return value === null;
  };

  public isValid = (value: any) => {
    return isValid(this.date(value));
  };

  public format = (value: Date, formatKey: keyof AdapterFormats) => {
    return this.formatByString(value, this.formats[formatKey]);
  };

  public formatByString = (value: Date, formatString: string) => {
    return dateFnsFormat(value, formatString, { locale: this.locale });
  };

  public formatNumber = (numberToFormat: string) => {
    return numberToFormat;
  };

  public getDiff = (value: Date, comparing: Date | string, unit?: AdapterUnits) => {
    switch (unit) {
      case 'years':
        return differenceInYears(value, this.date(comparing)!);
      case 'quarters':
        return differenceInQuarters(value, this.date(comparing)!);
      case 'months':
        return differenceInMonths(value, this.date(comparing)!);
      case 'weeks':
        return differenceInWeeks(value, this.date(comparing)!);
      case 'days':
        return differenceInDays(value, this.date(comparing)!);
      case 'hours':
        return differenceInHours(value, this.date(comparing)!);
      case 'minutes':
        return differenceInMinutes(value, this.date(comparing)!);
      case 'seconds':
        return differenceInSeconds(value, this.date(comparing)!);
      default: {
        return differenceInMilliseconds(value, this.date(comparing)!);
      }
    }
  };

  public isEqual = (value: any, comparing: any) => {
    if (value === null && comparing === null) {
      return true;
    }

    return isEqual(value, comparing);
  };

  public isSameYear = (value: Date, comparing: Date) => {
    return isSameYear(value, comparing);
  };

  public isSameMonth = (value: Date, comparing: Date) => {
    return isSameMonth(value, comparing);
  };

  public isSameDay = (value: Date, comparing: Date) => {
    return isSameDay(value, comparing);
  };

  public isSameHour = (value: Date, comparing: Date) => {
    return isSameHour(value, comparing);
  };

  public isAfter = (value: Date, comparing: Date) => {
    return isAfter(value, comparing);
  };

  public isAfterYear = (value: Date, comparing: Date) => {
    return isAfter(value, endOfYear(comparing));
  };

  public isAfterDay = (value: Date, comparing: Date) => {
    return isAfter(value, endOfDay(comparing));
  };

  public isBefore = (value: Date, comparing: Date) => {
    return isBefore(value, comparing);
  };

  public isBeforeYear = (value: Date, comparing: Date) => {
    return isBefore(value, startOfYear(comparing));
  };

  public isBeforeDay = (value: Date, comparing: Date) => {
    return isBefore(value, startOfDay(comparing));
  };

  public isWithinRange = (value: Date, [start, end]: [Date, Date]) => {
    return isWithinInterval(value, { start, end });
  };

  public startOfYear = (value: Date) => {
    return startOfYear(value);
  };

  public startOfMonth = (value: Date) => {
    return startOfMonth(value);
  };

  public startOfWeek = (value: Date) => {
    return startOfWeek(value, { locale: this.locale });
  };

  public startOfDay = (value: Date) => {
    return startOfDay(value);
  };

  public endOfYear = (value: Date) => {
    return endOfYear(value);
  };

  public endOfMonth = (value: Date) => {
    return endOfMonth(value);
  };

  public endOfWeek = (value: Date) => {
    return endOfWeek(value, { locale: this.locale });
  };

  public endOfDay = (value: Date) => {
    return endOfDay(value);
  };

  public addYears = (value: Date, amount: number) => {
    return addYears(value, amount);
  };

  public addMonths = (value: Date, amount: number) => {
    return addMonths(value, amount);
  };

  public addWeeks = (value: Date, amount: number) => {
    return addWeeks(value, amount);
  };

  public addDays = (value: Date, amount: number) => {
    return addDays(value, amount);
  };

  public addHours = (value: Date, amount: number) => {
    return addHours(value, amount);
  };

  public addMinutes = (value: Date, amount: number) => {
    return addMinutes(value, amount);
  };

  public addSeconds = (value: Date, amount: number) => {
    return addSeconds(value, amount);
  };

  public getYear = (value: Date) => {
    return getYear(value);
  };

  public getMonth = (value: Date) => {
    return getMonth(value);
  };

  public getDate = (value: Date) => {
    return getDate(value);
  };

  public getHours = (value: Date) => {
    return getHours(value);
  };

  public getMinutes = (value: Date) => {
    return getMinutes(value);
  };

  public getSeconds = (value: Date) => {
    return getSeconds(value);
  };

  public getMilliseconds = (value: Date) => {
    return getMilliseconds(value);
  };

  public setYear = (value: Date, year: number) => {
    return setYear(value, year);
  };

  public setMonth = (value: Date, month: number) => {
    return setMonth(value, month);
  };

  public setDate = (value: Date, date: number) => {
    return setDate(value, date);
  };

  public setHours = (value: Date, hours: number) => {
    return setHours(value, hours);
  };

  public setMinutes = (value: Date, minutes: number) => {
    return setMinutes(value, minutes);
  };

  public setSeconds = (value: Date, seconds: number) => {
    return setSeconds(value, seconds);
  };

  public setMilliseconds = (value: Date, milliseconds: number) => {
    return setMilliseconds(value, milliseconds);
  };

  public getDaysInMonth = (value: Date) => {
    return getDaysInMonth(value);
  };

  public getNextMonth = (value: Date) => {
    return addMonths(value, 1);
  };

  public getPreviousMonth = (value: Date) => {
    return addMonths(value, -1);
  };

  public getMonthArray = (value: Date) => {
    const firstMonth = startOfYear(value);
    const monthArray = [firstMonth];

    while (monthArray.length < 12) {
      const prevMonth = monthArray[monthArray.length - 1];
      monthArray.push(this.getNextMonth(prevMonth));
    }

    return monthArray;
  };

  public mergeDateAndTime = (dateParam: Date, timeParam: Date) => {
    return this.setSeconds(
      this.setMinutes(this.setHours(dateParam, this.getHours(timeParam)), this.getMinutes(timeParam)),
      this.getSeconds(timeParam),
    );
  };

  public getWeekdays = () => {
    const now = new Date();
    return eachDayOfInterval({
      start: startOfWeek(now, { locale: this.locale }),
      end: endOfWeek(now, { locale: this.locale }),
    }).map((day) => this.formatByString(day, 'EEEEEE'));
  };

  public getWeekArray = (value: Date) => {
    const start = startOfWeek(startOfMonth(value), { locale: this.locale });
    const end = endOfWeek(endOfMonth(value), { locale: this.locale });

    let count = 0;
    let current = start;
    const nestedWeeks: Date[][] = [];

    while (isBefore(current, end)) {
      const weekNumber = Math.floor(count / 7);
      nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
      nestedWeeks[weekNumber].push(current);

      current = addDays(current, 1);
      count += 1;
    }

    return nestedWeeks;
  };

  public getWeekNumber = (value: Date) => {
    return getWeek(value, { locale: this.locale });
  };

  public getYearRange = (start: Date, end: Date) => {
    const startDate = startOfYear(start);
    const endDate = endOfYear(end);
    const years: Date[] = [];

    let current = startDate;
    while (isBefore(current, endDate)) {
      years.push(current);
      current = addYears(current, 1);
    }

    return years;
  };

  public getMeridiemText = (ampm: 'am' | 'pm') => {
    return ampm === 'am' ? 'AM' : 'PM';
  };
}

interface OwnProps {
  children: React.ReactNode;
}

type Props = OwnProps;

const MuiPickersUtilsProvider: React.FunctionComponent<Props> = ({ children }) => {
  const { t, language } = useLocale();

  const localeTexts = useMemo(
    () => ({
      previousMonth: t(DATE_PICKERS_PREVIOUS_MONTH),
      nextMonth: t(DATE_PICKERS_NEXT_MONTH),

      // View navigation
      openPreviousView: 'open previous view',
      openNextView: 'open next view',

      // DateRange placeholders
      start: 'Start',
      end: 'End',

      // Action bar
      cancelButtonLabel: t(DATE_PICKERS_CANCEL_BUTTON_LABEL),
      clearButtonLabel: t(DATE_PICKERS_CLEAR_BUTTON_LABEL),
      okButtonLabel: t(DATE_PICKERS_OK_BUTTON_LABEL),
      todayButtonLabel: t(DATE_PICKERS_TODAY_BUTTON_LABEL),

      // Clock labels
      clockLabelText: (view, time, adapter) =>
        `Select ${view}. ${
          time === null ? 'No time selected' : `Selected time is ${adapter.format(time, 'fullTime')}`
        }`,
      hoursClockNumberText: (hours) => `${hours} hours`,
      minutesClockNumberText: (minutes) => `${minutes} minutes`,
      secondsClockNumberText: (seconds) => `${seconds} seconds`,

      // Open picker labels
      openDatePickerDialogue: (rawValue, utils) =>
        rawValue && utils.isValid(utils.date(rawValue))
          ? `Choose date, selected date is ${utils.format(utils.date(rawValue)!, 'fullDate')}`
          : 'Choose date',
      openTimePickerDialogue: (rawValue, utils) =>
        rawValue && utils.isValid(utils.date(rawValue))
          ? `Choose time, selected time is ${utils.format(utils.date(rawValue)!, 'fullTime')}`
          : 'Choose time',

      // Table labels
      timeTableLabel: 'pick time',
      dateTableLabel: 'pick date',
    }),
    [t],
  );

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateFnsLocales[language]}
      localeText={localeTexts}
    >
      {children}
    </LocalizationProvider>
  );
};

MuiPickersUtilsProvider.displayName = 'MuiPickersUtilsProvider';

export default MuiPickersUtilsProvider;
