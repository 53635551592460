
            import createOneQuery from '@engined/core/build/graphql-plugin-lib';
            import _asaprint_asap_fragments from '@asaprint/asap/fragments.graphql';
            const doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"App_Load"},"variableDefinitions":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestLanguage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"me"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RequestUser"},"directives":[]}]}}]}},{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"App_Logout"},"variableDefinitions":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logout"},"arguments":[],"directives":[]}]}},{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"App_ServerToastReceived"},"variableDefinitions":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"serverToastReceived"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"message"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":256}};
            doc.loc.source = {"body":"\n          #import '@asaprint/asap/fragments.graphql'\n\nquery App_Load {\n  requestLanguage\n  me {\n    ...RequestUser\n  }\n}\n\nmutation App_Logout {\n  logout\n}\n\nsubscription App_ServerToastReceived {\n  serverToastReceived {\n    type\n    message\n  }\n}\n\n        ","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
          

    const names = new Set<string>();
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          const name = def.name.value
          if (names.has(name)) {
            return false;
          } else {
            names.add(name);
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(_asaprint_asap_fragments.definitions));


              const oneQuery = createOneQuery(doc);
              export default doc;
            
                  export const App_Load = oneQuery(doc, "App_Load");
                
                  export const App_Logout = oneQuery(doc, "App_Logout");
                
                  export const App_ServerToastReceived = oneQuery(doc, "App_ServerToastReceived");
                
